/* Main FAQ container */
.faq-container {
  width: 91%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* FAQ title */
.faq {
  display: block;
  font-size: 24px;
  font-weight: bold;
  color: #003057;
  margin-bottom: 20px;
}

/* FAQ list container */
.faq-list {
  margin-top: 20px;
}

/* FAQ category section */
.faq-category {
  margin-bottom: 30px;
}

/* Category title */
.faq-category-title {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px;
  background: #00507a;
  border-radius: 8px;
  /* transition: background 0.2s ease; Smooth background color transition */
}


/* Individual FAQ item */
.faq-item {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, box-shadow 0.2s ease; /* Smooth background color and box-shadow transition */
  cursor: pointer;
}

.faq-item:hover {
  background-color: #f1f1f1;
}

.faq-item.open {
  background-color: #e7f5ff; /* Color when item is open */
}

/* Question text */
.faq-question p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #003057;
}

/* Answer text */
.faq-answer {
  max-height: 0; /* Initially hide answer */
  overflow: hidden; /* Hide overflow */
  margin-top: 10px;
  padding-left: 20px;
  font-size: 15px;
  color: #555555;
  transition: max-height 0.4s ease, opacity 0.4s ease; /* Smooth transition for max-height and opacity */
  opacity: 0; /* Initially hidden */
}

/* When the item is open */
.faq-item.open .faq-answer {
  max-height: 500px; /* A large enough value to accommodate the answer's height */
  opacity: 1; /* Fully visible when open */
}

/* Responsive styles */

/* Mobile styles */
@media (max-width: 600px) {
  .faq {
    font-size: 20px;
  }
  .faq-category-title {
    font-size: 18px;
  }
  .faq-question p {
    font-size: 14px;
  }
  .faq-answer {
    font-size: 13px;
  }
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 900px) {
  .faq {
    font-size: 22px;
  }
  .faq-category-title {
    font-size: 19px;
  }
  .faq-question p {
    font-size: 15px;
  }
  .faq-answer {
    font-size: 14px;
  }
}

/* Laptop styles */
@media (min-width: 901px) and (max-width: 1200px) {
  .faq {
    font-size: 24px;
  }
  .faq-category-title {
    font-size: 20px;
  }
  .faq-question p {
    font-size: 16px;
  }
  .faq-answer {
    font-size: 15px;
  }
}

/* Desktop styles */
@media (min-width: 1201px) {
  .faq {
    font-size: 26px;
  }
  .faq-category-title {
    font-size: 21px;
  }
  .faq-question p {
    font-size: 17px;
  }
  .faq-answer {
    font-size: 16px;
  }
}
