.footer {
  background-color: #f8f8f8; /* Light background for footer */
  color: #1a4164;               /* Dark text color */
  text-align: center;        /* Centered text */
  padding: 0px;            /* Padding around the footer */
  border-top: 1px solid #ddd; /* Light top border */
  position: relative;        /* Ensure proper positioning */
  bottom: 0;                 /* Stick to the bottom of the page */
  width: 100%;               /* Full width */
  font-family: 'Montserrat', sans-serif; /* Default font for the container */
}

.footer p {
  margin: 10px 0;           /* Space above and below paragraph */
  font-size: 14px;          /* Font size for copyright */
  font-family: 'Montserrat', sans-serif; /* Default font for the container */
  padding-top: 1%;
  margin-bottom: 0%;
  color: #1a4164;  
  font-weight: 600;
}

.email,
.add {
  display: block;           /* Stack spans vertically */
  font-size: 14px;         /* Font size for contact info */
  margin: 5px 0;           /* Space between spans */       
  font-family: 'Montserrat', sans-serif; /* Default font for the container */
  color: #1a4164;     /* text color */
}
/* .contact{
  font-size: 14px;
  font-family: 'Montserrat', sans-serif; /* Default font for the container 
  font-weight: 300;
  color:  #1a4164;
} */