@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/open-sauce-one');

/* General Container Styling */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif; /* Default font for the container */
}

/* Styling for the info section */
.info {
  position: relative;
  min-height: 100vh;
}

.header1{
  display: block;
  unicode-bidi: isolate;
}

.logo {
  width: 250px;
  height: 100px;/* Maintain aspect ratio */
  padding: 7px;
  padding-left: 0%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding-top: 22vh;
  font-size: 1.2em;
}

.enroll-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f7f7f7;
  color: #1a4164;
  border: none; 
  cursor: pointer;
  font-size: 1.1em;
  margin-bottom: 20px;
  font-weight: bold;
}

.enroll-btn:hover {
  background-color: #98a6b4;
}

/* Styling for text and image sections */
.s-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text-section {
  width: 45%; /* Adjust the width of the text section */
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  text-align: justify;
}

.image-section {
  width: 50%;
  height: 900px; /* Set a fixed height for the image section */
  overflow: hidden; /* Hide any overflow */
  position: relative; /* Positioning context for the image */
}


.image-section img {
  width: 100%;
  height: 100%; /* Make the image fill the height of its container */
  object-fit: cover; /* This keeps the aspect ratio and covers the entire container */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .s-container {
    flex-direction: column;
    padding: 10px;
  }

  .text-section,
  .image-section {
    width: 100%; /* Set both sections to full width on smaller screens */
    height: auto; /* Allow the image section to adjust height */
  }

  .image-section img {
    height: auto; /* Make image height auto to maintain aspect ratio */
  }
}

/* Keep the same height for both images on larger screens */
@media (min-width: 768px) {
  .s-container {
    display: flex;
    flex-direction: row;
  }

  .text-section {
    height: 900px; /* Match the height of the image section */
  }

  .image-section {
    height: 900px; /* Ensure both sections maintain the same height */
  }
}

/* Specific styling for the text-adjustment div */
.text-adjustment {
  font-size:x-large;
  line-height: 130%;
  padding: 30px 30px 0 30px;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to this section */
}

.text-adjustment h3 {
  color: #1a4164;
  font-weight: bolder;
  font-size:xx-large;
}

.text-adjustment p {
  color: #154854;
}


.text-adjustment-t {
  font-size:x-large;
  line-height: 130%;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to this section */
}

.text-adjustment-t h3 {
  color: #1a4164;
  font-weight: bolder;
}

.text-adjustment-t p {
  color: #154854;
}

.t-container {
  width: 100%;
  height: auto;
  background-color: #154854;
}

.t-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sw-pr {
  color: white;
  font-weight: bolder;
  margin-top: 80px;
  font-size: 50px;
  font-family: 'Montserrat', sans-serif !important; /* Default font for the container */
  text-align: center;

}

.what-we-text {
  color: white;
  font-family: 'Montserrat', sans-serif !important; /* Default font for the container */
  font-size: 40px;
  font-weight: lighter;
  margin-top: 10px;
} 

.cards-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Ensures cards wrap on smaller screens */
  margin-top: 20px;
}

.f-card {
  width: 430px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 15px;
  margin: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.f-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.f-card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.f-card-title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1a4164;
}

.f-card-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
}

/* Styling for the card container */
.card {
  width: 300px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', sans-serif; /* Optional: Apply your font */
  margin: 20px;
}

/* Styling for the title */
.card-title {
  background-color: #f7f7f7;
  padding: 15px;
  text-align: center;
  font-weight: 700;
  color: #154854;
}

/* Styling for the image */
.card-image img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 5%;
}

.card-image {
  padding: 0 10px;
}

/* Styling for the card content */
.card-content {
  padding: 15px;
  text-align: center;
  font-size: 1em;
  margin-bottom:70px
}

.benefits-container {
  text-align: center; /* Center text */
  /* padding: 40px;  */
  background-color: rgb(255, 255, 255); 
}


.benefits-title {
  font-size: 2.5rem; 
  font-weight: bold; 
  color: #004080; 
  margin-bottom: 30px; 
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 25px; 
}

@media(max-width:768px) {

  .benefits-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.benefit {
  background-color: #ffffff; 
  border-radius: 20px; 
  padding: 30px;
  transition: transform 0.3s;
}

.benefit:hover {
  transform: translateY(-5px);
}

.benefit img {
  width: 300px;
  height: auto;
  margin-bottom: 15px;
}

.benefit h3 {
  font-size: 2.5rem;
  font-weight: 100;
  color: #1a4164;
  margin: 0;
}

.fi-container {
  background-image: url('../assest/lastSection.jpg'); /* Use the image as a background */
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  color: white; /* Change text color if needed */
  display: flex;
  justify-content: space-around;
}

.fi-text {
  width: 50%;
  text-align: justify;
  padding: 20px;
}

.fi-image {
  width: 50%;
  height: auto;
}

.fi-image-section {
  margin-right: 20px;
  margin-left: 23px;
}

.fi-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 992px) {
  .fi-container {
    flex-direction: column;
    align-items: center;
  }

  .fi-text,
  .fi-image {
    width: 100%;
  }

  .fi-text {
    padding: 20px 10px;
  }
}
@media (max-width: 768px) {
  .fi-text {
    padding: 15px;
  }

  p {
    text-align: left;
  }
  
  .fi-text p {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .fi-text {
    padding: 10px;
  }

  .fi-text p {
    font-size: 0.9em;
  }

  .fi-image img {
    height: auto;
    max-height: 400px;
    /* Optional to prevent very tall images on mobile */
  }
}

.htgs {
    margin-top: 150px;
    font-size: 45px;
    font-weight: bolder;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.htgs-q {
  margin-top: 25px;
  font-size: 37px;
  font-weight: lighter;
  color: white;
  font-family: 'Montserrat', sans-serif; /* Default font for the container */
  text-align: center;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  /* Add padding for better spacing */
  width: 100%;
  max-width: 800px;
  /* Set a max width for the form container */
  margin: 0 auto;
  /* Center the container */

}

.enquiry-form {
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  padding: 2rem;
  padding-left: 35px;
  padding-right: 65px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  /* Maximum width for the form */
  text-align: center;
}

.enquiry-form h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: #154854;
  margin-bottom: 1.5rem;
}

.enquiry-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem; /* Increased margin for better spacing */
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
}
.enquiry-form input::placeholder {
  color: #b8b8b8;
}

.enroll-button {
  background-color: #154854;
  color: #ffffff;
  padding: 12px 20px;
  border: 1px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 40%;
  margin-top: 1rem;
  font-weight: bold;
}
.enroll-button:hover {
  background-color: #999fa5;
}
.disclaimer {
  font-size: 12px;
  color: #000000;
  margin-top: 1rem;
  text-align: left;
}

.fi-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;

}

/* Disclaimer container styling */
.disclaimer-container {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center the items */
  margin: 10px 0; /* Add some space around the element */
}

.disclaimer-text {
  margin-right: 15px; /* Space between the radio button and text */
  font-size: 11px; /* Adjust font size if needed */
  text-align: start;
  color: #1a4164;
  font-weight: 600;
}

.radio{
  padding: 2.5%;
}
/* Radio button styling */
input[type="radio"] {
  cursor: pointer;
}
/* Styles for the social-links section */
.social-links {
  text-align: center; /* Aligns text to the left */
  margin-top: 20px;
  padding-left: 0; /* Removes any left padding */
}

/* To make sure the content is placed at the extreme left */
.social-links-container {
  display: flex;
  justify-content: flex-start; /* Aligns the content to the left */
  align-items: flex-start;
  margin-left: 0; /* Removes any left margin */
}

/* Styles for the heading */
.social-links h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;
}

/* Styles for the container holding the social icons */
.social-icons {
  display: flex;
  justify-content: center; /* Aligns icons to the left */
  gap: 0px;
}

/* Styles for the individual icon images */
.icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Hover effect for the icons */
.icon:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
