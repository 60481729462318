/* General header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px; /* Adjust padding for larger screens */
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
  color: white;
  box-shadow: none; /* Remove shadow for a cleaner look */
  position: fixed; /* Keep header fixed at the top */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  z-index: 100; /* Ensure header is above other content */
  transition: background-color 0.3s ease; /* Smooth transition for background */
}

.logo img {
  height: 60px; /* Logo height */
  width: auto; /* Maintain aspect ratio */
}

/* Navigation styling */
nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-left: 30px; /* Space between navigation items */
}

nav ul li a {
  text-decoration: none; /* Remove underline */
  color: white; /* Link color */
  font-size: 1.1rem; /* Link font size */
  font-weight: 500; /* Font weight */
  transition: color 0.3s ease; /* Transition for hover effect */
}

nav ul li a:hover {
  color: #f0a500; /* Hover color for links */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .header {
    padding: 15px 20px; /* Reduce padding on smaller screens */
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center text */
  }

  .logo img {
    height: 50px; /* Smaller logo height for mobile */
  }

  nav ul {
    flex-direction: column; /* Stack navigation items vertically */
    margin-top: 10px; /* Space above navigation */
  }

  nav ul li {
    margin: 10px 0; /* Space between navigation items */
  }

  nav ul li a {
    font-size: 1rem; /* Smaller font size for links on mobile */
  }
}
